import React from 'react';
import { useCookies } from "react-cookie";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';
import OrdersMenu from '../../components/orders_menu';

import {fetchOrders, fetchRequest, getProductDescription} from '../../controllers/orders_functions';

  //  TODO: Backend url to config
  var backend_url = "https://crm.bomat.fi";

  if (window.location.protocol === "file:" || window.location.hostname === "localhost") {
    backend_url = "https://localhost";
  }



function addCookies(Component) {
    return function WrappedComponent(props) {
        const [cookies] = useCookies();
      return <Component {...props} cookies={cookies} />;
    }
  }

class Orders extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            error: null,
            orderdata: null,
            showModal: false,
            showDescriptionModal: false,
        };
        
        this.amountRef = React.createRef(null);
        this.invoiceRef = React.createRef(null);
    }

    async componentDidMount() {

        try {
            var orders = await fetchOrders(this.props.cookies.token, backend_url);

            if (orders.error) {
                this.setState({ error: orders.msg });
            }
            else {
                this.setState({ loading: false });
                this.setState({ orderdata: orders.data });
            }
        } catch (error) {
            console.log("error: ", error);
            this.setState({ error: "Tietojen haussa tapahtui virhe."});
        }
    }

    openDescriptionModal = async (row, e) => {
        
        try {
            var result = await getProductDescription(row.product_code, row.product_desc, this.props.cookies.token, backend_url);
            var parent = this;

            if (Object.keys(result).length > 0) {              
                this.checkChildren([result.data], 0, function( html_rows ) {
                    parent.setState({ html_rows: html_rows });
                });
                
                this.setState({
                    showDescriptionModal: true,
                    showModal: false,
                    descriptionModalTitle: row.product_code + " " + row.product_desc,
                });
            }
        } catch (error) {
            console.log("error: ", error);
            this.setState({ error: "Tietojen haussa tapahtui virhe."});
        }
    }
      
    ucfirst = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
      }

    checkChildren = (items, level=0, callback) => {

        var html = "";
        var upperCaseWords = [];
      
          for (const [product] of Object.entries(items)) {
            for (const [data] of Object.entries(product)) {
            
              // Näytetään vain ulkoiset kortit, koska vain niissä voi olla tuoteseloste
              if (data.inhouse_produced === 0){
                if (!data.content) {
                  // Raaka-aineen tietoja ei löydy
                  data.content = "<i class='small'>Raaka-ainetiedot puuttuvat.</i>";
                }
                else {
                  // Etsitään kaikki isolla kirjoitetut sanat
                  upperCaseWords = data.content.match(/(\b[A-ZÄÅÖ][A-ZÄÖ]+|\b[A-ZÄÅÖ]\b)/g);
      
                  if (upperCaseWords) {
                    
                    // Siivotaan pois useammin kuin kerran esiintyvät sanat
                    let uniqueUpperCaseWords = [...new Set(upperCaseWords)];
        
                    // Käydään läpi kaikki isolla kirjoitetut sanat
                    for (var j = 0; j < uniqueUpperCaseWords.length; j++) {
                      
                      // Lihavoidaan vain 3 merkkiä pidemmät sanat
                      if (uniqueUpperCaseWords[j].length > 3) {
                        data.content = data.content.replaceAll(uniqueUpperCaseWords[j], "<b>" + uniqueUpperCaseWords[j] + "</b>");
                      }	
                    }
                  }
                }
        
                html += "<div class='row ms-2'><div class='col-12'><b>";
        
                let tuotenimi = this.ucfirst(data.name);
        
                // Etsitään " 100g " tyyppistä merkkijonoa
                var splitter = new RegExp(' ([0-9][0-9]{0,3})g ').exec(data.name);
        
                // Jos löytyy paino, katkaistaan nimi siitä.
                if (splitter) {
                   var parts = data.name.split(splitter[0]);
                   tuotenimi = this.ucfirst(parts[0]);
                }
        
                if (data.common_name != null) {
                  tuotenimi = this.ucfirst(data.common_name);
                }
        
                html += tuotenimi + "</b></div></div>";
                
                html += "<div class='row mb-1 ms-2'><div class='col-12'>"
                // Vain annoskorteissa näytetään raaka-ainetiedot
                if (!data.commodity) { html += data.content }
                html += "</div></div>";	
                          
              }
              else {
                // tarkistetaan halutaanko "välitaso" näyttää
                if (data.visible) {
                  html += "<div class='row ms-2'><div class='col-12'>";
                  html += "<b>" + this.ucfirst(data.name) + "</b>";  
                }
              }
        
              // Haetaan mahdolliset ali-reseptit
              if (data.children) {
                // eslint-disable-next-line 
                this.checkChildren(data.children, level+1, function(sub_html) {
                  html += sub_html;
                });
              }
              // Lisätään välitason lopetus kun lapset on listattu
              if (data.visible) {
                html += "</div></div>";	
              }
            }
          }
          callback(html);
      };

    sendRequest = async () => { 

        var newAmount = this.amountRef.current.value;
        var selectedKey = this.invoiceRef.current.value;

        if (newAmount === "" || newAmount == null || newAmount === this.selectedAmount ) {
            this.setState({amountErrorMsg: 'Tarkasta lukumäärä'});
        }
        else {
            this.setState({amountErrorMsg: ''});

            try {
                var fetch = await fetchRequest(this.props.cookies.token, backend_url, newAmount, selectedKey);
                
                if (fetch.error) {
                     alert(fetch.msg)
                }

                // Haetaan päivitetyt tiedot
                try {
                    var orders = await fetchOrders(this.props.cookies.token, backend_url);
        
                    if (orders.error) {
                        this.setState({ error: "orders.msg "});
                    }
                    else {
                        this.setState({ loading: false });
                        this.setState({ orderdata: orders.data });
                    }
                } catch (error) {
                    console.log("error: ", error);
                    this.setState({ error: "Tietojen haussa tapahtui virhe."});
                }
                this.setState({showModal: false});
                
            } catch (error) {
                this.setState({ error: error});
            }
        }
    }


    openRequestModal = (row, e) => {   

        this.setState({ 
            selectedId: row.product_code,
            selectedName: row.product_desc,
            selectedAmount: row.product_amount,
            selectedTime: row.delivery_time,
            selectedDate: row.delivery_date,
            selectedKey: row.key,
            showModal: true,   
            showDescriptionModal: false,
            
        });
      }

    closeModals = () => this.setState({ showModal: false, showDescriptionModal: false});

    PrintButton(){
        function handlePrint() {
            window.print();
        }
        return(
          <button className='btn btn-warning' onClick={handlePrint}> Tulosta </button>
        )
      }
      
    render() {
        const { 
            orderdata, 
            loading, 
            error, 
            showModal,
            showDescriptionModal,
            selectedName,
            selectedId,
            selectedDate,
            selectedTime,
            selectedAmount,
            selectedKey,
            amountErrorMsg,
            descriptionModalTitle,
            html_rows,
        } = this.state;
        
        return (
            <div>
                { loading && <h5 className="text-center">Haetaan tietoja...</h5> }
                { error && <p>Error: {error}</p> }
                { orderdata && 
                    
                    <div>
                        <div className="overlay" style={{ backgroundImage: `url('images/02.jpg')`, opacity: 0.25, zIndex: 0, backgroundRepeat: 'no-repeat' }}></div>
                        <div className='d-none d-sm-none d-md-block mt-50'></div>
                        <div className="content">
                            <div className="container d-print-none mb-5">
                                <div className="row">
                                    <div className="col-md-3 col-xs-12 mb-4">
                                        < OrdersMenu />
                                    </div>
                                    <div className="col-md-9 col-xs-12">
                                        <Accordion defaultActiveKey={0}>
                                            {Object.keys(orderdata).map((company_name, i) => (
                                            <Accordion.Item eventKey={i} key={i}>
                                                <Accordion.Header><h2 className='mb-0'>{company_name}</h2></Accordion.Header>
                                                <Accordion.Body>
                                                {Object.keys(orderdata[company_name]).map((date, j) => (
                                                    <div className="card mb-1" key={company_name + "-" + date + "-" + j}>
                                                    <div className="card-header" aria-expanded="true">
                                                        <label className="mb-0">Toimituspäivä: {date}</label>
                                                    </div>
                                                    <div className="card-body">
                                                        <table className="table">
                                                        <thead>
                                                            <tr>
                                                            <th style={{width: '15%'}}>Tuotekoodi</th>
                                                            <th style={{width: '50%'}}>Nimi</th>
                                                            <th style={{width: '10%'}}>Lkm</th>
                                                            <th style={{width: '35%'}}>Tila</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {Object.keys(orderdata[company_name][date].products).map((row, k) => (
                                                            <tr key={company_name + "-" + date + "-" + k}>
                                                                <td>{orderdata[company_name][date]['products'][row].product_code}</td>
                                                                <td style={{ cursor: "pointer" }} onClick={this.openDescriptionModal.bind(this, orderdata[company_name][date]['products'][row])}>
                                                                    {orderdata[company_name][date]['products'][row].product_desc}
                                                                </td>
                                                                { orderdata[company_name][date]['products'][row].pending !== "Odottaa käsittelyä" && 
                                                                <td style={{ cursor: "pointer" }} onClick={this.openRequestModal.bind(this, orderdata[company_name][date]['products'][row])}>
                                                                    {orderdata[company_name][date]['products'][row].product_amount} kpl
                                                                </td>
                                                                }
                                                                { orderdata[company_name][date]['products'][row].pending === "Odottaa käsittelyä" && 
                                                                <td>
                                                                    {orderdata[company_name][date]['products'][row].product_amount} kpl
                                                                </td>
                                                                }
                                                                <td>{orderdata[company_name][date]['products'][row].pending}</td>
                                                            </tr>
                                                            ))}
                                                        </tbody>
                                                        </table>
                                                        
                                                        Lisätilaus <select>
                                                        {Object.keys(orderdata[company_name][date].available).map((row, k) => (
                                                        <option key={k} value='{row}'>{orderdata[company_name][date].available[row]}</option>
                                                        ))}
                                                        </select>
                                                        <input className='ms-4 me-1' id="newOrderAmount" type="number" max="999" min="0" defaultValue={selectedAmount} ref={ this.amountRef } />kpl
                                                        <input className='ms-4' type="submit" value="lähetä"></input>
                                                    </div>
                                                    </div>
                                                ))}

                                                </Accordion.Body>
                                            </Accordion.Item>
                                            ))}
                                        </Accordion>
                                    </div>
                                </div>
                            </div>
                        </div>

                            <Modal show={showModal} onHide={this.closeModals}>
                                <Modal.Header closeButton>
                                    <Modal.Title><h5 className="modal-title">Tee tilaukseen muutospyyntö</h5></Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <p>
                                    Tuote: {selectedName}<br />
                                    Tuotenumero: {selectedId}<br />
                                    Toimitusaika: {selectedDate}, kello {selectedTime}<br />
                                    Alkuperäinen määrä: {selectedAmount} kpl
                                    </p>
                                    <div>
                                    <div id="new_amount_input">
                                        Syötä toivomasi uusi määrä: <input id="newAmount" type="number" max="999" min="0" defaultValue={selectedAmount} ref={ this.amountRef } /><span className="text-danger ms-2">{amountErrorMsg}</span>
                                    </div>
                                    <div id="new_amount_disabled" style={{ display: "none" }}>
                                        Tilausta ei voi enää muuttaa.
                                    </div>
                                    <input type="hidden" name="invoice_id" id="invoice_id" defaultValue={selectedKey} ref={ this.invoiceRef } /><br />
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="warning" onClick={this.sendRequest} >Lähetä</Button>
                                    <Button variant="light" onClick={this.closeModals} >Peruuta</Button>
                                </Modal.Footer>
                            </Modal>

                            <Modal show={showDescriptionModal} onHide={this.closeModals} dialogClassName="descriptionModal" 
                                backdropClassName='d-print-none'
                                contentClassName='w-100'
                                className='w-100'>

                                <Modal.Header closeButton className="d-print-none">
                                    <Modal.Title><h5 className="modal-title">{descriptionModalTitle}</h5></Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                        <h5 className="modal-title d-print-block d-none mb-4">{descriptionModalTitle}</h5>
                                        <div dangerouslySetInnerHTML={{ __html: html_rows, }} ></div>
                                </Modal.Body>
                                <Modal.Footer className="d-print-none">
                                    <this.PrintButton />
                                    <Button variant="light" onClick={this.closeModals} >Peruuta</Button>
                                </Modal.Footer>
                            </Modal>
                    </div>    
                }
            </div>
        );
    }
}

const OrdersPage = {
    routeProps: {
        path: '/customers/orders',
        Component: addCookies(Orders)
    },
    name: 'Orders',
}
export default OrdersPage;
