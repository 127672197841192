import React, { useState } from 'react';


// Luo tilaushistoria menun
export default function Menu({ currentTab, years, sendDataToParent, sendMonthToParent } ) {
    
    const [selectedYear, setYear] = useState(null);
    const [selectedMonth, setMonth] = useState(null);

    function selectYear(year) {
        sendMonthToParent(null);
        sendDataToParent(year);
        // Piilottaa kaikki kuukaudet
        document.querySelectorAll(".monthSelector").forEach((element) => element.classList.add("d-none"));
        // Näyttää valitun vuoden kuukaudet
        document.querySelectorAll(".year_" + year).forEach((element) => element.classList.remove("d-none"))
        setYear(year);
        setMonth(null);
    }

    function selectMonth(month) {
        setMonth(month);
        sendMonthToParent(month);
    }

    return ( 
        <div>    
            <ul className="list-unstyled">
                <li><a href="/customers/orders">Avoimet tilaukset</a></li>
                <li><a href="/customers/orderhistory">Tilaushistoria</a></li>
                {currentTab === 'History' && 
                
                    <li className="ps-3">
                    {Object.keys(years).map((year, i) => (  
                        <ul className="list-unstyled" key={i}>
                         
                            <li className={ (year === selectedYear ? 'fw-bold' : undefined) } onClick={()=>selectYear(year)} key={i}>{year}</li>
                            <ul className="list-unstyled">
                                { Object.keys(years[year]).map((month_number, i2) => (  
                                    <li className={'ps-3 ' + (year === selectedYear ? '' : 'd-none ') + (month_number === selectedMonth ? 'fw-bold ' : '') + `monthSelector year_${year}`} 
                                        key={years[year] + "-" + i2}
                                        onClick={() => selectMonth(month_number)}>
                                        {years[year][month_number].name}
                                        
                                    </li>
                                ))
                                }
                            </ul>
                        </ul>
                    ))}

                    </li>
                }
            </ul>
        </div>
    )
  }
