import Front from './front'; 
import Contact from './contact';
import Products from './products'; 
import Customers from './customers'
import Login from './customers/login'
import Logout from './customers/logout'
import Password from './customers/password'
import SetPassword from './customers/setpassword'
import Orders from './customers/orders'
import Orderhistory from './customers/orderhistory'
import Video from './video'
import Cookies from './cookies'

const index = [
    Front,
    Contact,
    Products,
    Customers,
    Login,
    Logout,
    Orders,
    Orderhistory,
    Video,
    Cookies,
    Password,
    SetPassword
];

export default index;