import { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import { useCookies } from "react-cookie";
import CookieConsent from "react-cookie-consent";
import ReactGA from 'react-ga4';

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';

import './App.css';
import modules from './modules'; 

const popover = (
  <Popover id="popover-basic">
    <Popover.Header as="h3">Puheluhinnat</Popover.Header>
    <Popover.Body>
    Lankapuhelinverkosta 8,35 snt/puh. + 6,00 snt/min (alv24%). Matkapuhelinverkosta 8,35 snt/puh. + 17,17 snt/min (alv 24%).
    Asiakaspalvelu avoinna arkisin kello 06:00 - 14:00.
    </Popover.Body>
  </Popover>
);
const pointer = {
  cursor:"pointer"
}

export const NotFound = () => <h3>Sivua ei löytynyt</h3>;

// Google seuranta
ReactGA.initialize('G-0S855QMBW2', { debug: true });

function App() {
  const [currentTab, setCurrentTab] = useState('dashboard');
  const [cookies] = useCookies();
  
  let className = 'nav-item';

  // Lisätään sivu näyttö tapahtuma googleen
  ReactGA.send({ hitType: "pageview", page: window.location.pathname });

  return (
    <Router>
      <div className="App d-print-none">
        <header className="App-header">
          <nav className="navbar navbar-expand-sm navbar-light">
            <div className="container-fluid">
              <div className='navbar-brand'>
                <img src="/images/logo.png" className="App-logo" alt="Bomat"></img>
              </div>

              <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
              </button>

              <div className="float-right collapse navbar-collapse" id="navbarNav">
                <ul className="navbar-nav ms-auto me-4 mb-2 mb-lg-0">
                  {modules.map(module => ( // with a name, and routes
                    !module.name.includes("Asiakkaille") &&
                    !module.name.includes("Video") &&
                    !module.name.includes("Evästekäytäntö") &&
                    !module.name.includes("Login") &&
                    !module.name.includes("Logout") &&
                    !module.name.includes("Password") &&
                    !module.name.includes("Orders") &&
                    !module.name.includes("Orderhistory") &&
                    (
                      <li key={module.name} className={currentTab === module.name ? className + '-active' : className + ''}>
                        <Link to={module.routeProps.path} onClick={() => setCurrentTab(module.name)} className='nav-link'>{module.name} </Link>
                      </li>
                    )
                  ))}
                  {!cookies.token && ( 
                    <li className="nav-item ms-4" key='Login'>
                      <Link className={'btn btn-outline-dark'} to='/customers/login' onClick={() => setCurrentTab('Login')}>Login</Link>
                    </li>
                  )}

                  {cookies.token && ( 
                    <li className={currentTab === 'orders' ? 'nav-item-active' : 'nav-item'}  key='Orders'>
                      <Link className='nav-link' to='/customers/orders' onClick={() => setCurrentTab('orders')}>Tilaukset</Link>
                    </li>
                  )}

                  {cookies.token && ( 
                    <li className="nav-item ms-4" key='Logout'>
                    <Link className={'btn btn-outline-dark'} to='/customers/logout' onClick={() => setCurrentTab('Logout')}>Logout</Link>
                  </li>
                  )}
                  
                  <li className="nav-item ms-4" key='Asiakkaille'>
                    <Link className={currentTab === 'Asiakkaille' ? 'btn btn-dark' : 'btn btn-outline-dark'} to='/asiakkaille' onClick={() => setCurrentTab('Asiakkaille')}>Asiakkaille</Link>
                  </li>

                </ul>
              </div>
            </div>
          </nav>
        </header>

        <div className="App-content mt-4">
          <Routes>
            {modules.map((module) => (
              <Route {...module.routeProps} key={module.name} />
            ))}
            <Route path="*" element={<NotFound />} key='others' />
            <Route path="/tilaukset" Component={modules[3].routeProps.Component} key='tilaukset' />
          </Routes>
        </div>
        <div style={{ paddingTop: 10 + 'em' }} ></div>

        <CookieConsent
          location="top"
          buttonText="Selvä"
          cookieName="bomatUsageCookie"
          style={{ background: "#2B373B" }}
          buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
          expires={150}
        >
          Tämä sivusto käyttää evästeitä sujuvan palvelun mahdollistamiseksi. Jatkamalla sivuston käyttöä hyväksyt myös evästeiden käytön. Halutessasi voit lukea lisää <a href='/evastekaytanto'>evästekäytännöstämme</a> {" "}
        </CookieConsent>


        <div className="App-footer fixed-bottom">

          <div className="container my-2">
            <div className='row'>
              <div className='col-12 col-sm-6 col-md-3 text-white-50 px-0 '>
                <b>Bomat Oy</b><br />
                Vanha Talvitie 8b K1, 00580 Helsinki<br />
              </div>
              <div className='col-12 col-sm-6 col-md-3 text-white-50'>email: info@bomat.fi

              <OverlayTrigger trigger="click" placement="top" overlay={popover}>
                <p style={ pointer }> puh: 010 235 8800*</p>
              </OverlayTrigger>
              </div>
              <div className='col-12 col-sm-12 col-md-6'>
                <div className="container mt-1">
                  <div className="row">
                    <div className="col-3" style={{'display': 'flex', 'align-items': 'center'}}>
                      <a href="https://zeckit.com/selvitys/FI/0944172-6?section=quickcheck" rel="noreferrer" target="_blank"><img src="https://www.vastuugroup.fi/hubfs/vastuu-group/VastuuGroup_Logo_Musta_Rgb.png" className='iconimage' style={{ width:'120px', 'height': 'auto', '-webkit-filter': 'invert(1)', 'filter': 'invert(1)' }} alt="Luotettava Kumppani" /></a>
                    </div>
                    <div className="col-3">
                      <a href="https://www.yrittajat.fi/" rel="noreferrer" target="_blank"><img src="images/yrittajat.png" className='iconimage' alt="Suomen Yrittäjät jäsenyritys" /></a>
                    </div>
                    <div className="col-3">
                      <a href="https://www.kauppakamariverkosto.fi/jasenprofiilit/bomat-oy.html" rel="noreferrer" target="_blank"><img src="images/kkjasen.png" className='iconimage' alt="Kauppakamari jäsenyritys" /></a>
                    </div>
                    <div className="col-3">
                      <a href="https://www.oivahymy.fi/hae-yrityksia/#text=bomat" rel="noreferrer" target="_blank"><img src="images/oiva.gif" className='px-4 iconimage' alt="Suomen Yrittäjät jäsenyritys" /></a>
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <div className='row'>
              <div className='col-12 text-white-50'>
                <small><a href="/evastekaytanto">Evästekäytäntö</a></small>
              </div>
            </div>
          </div>


        </div>

      </div>
    </Router>
  );
}

export default App;
