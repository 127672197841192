import React from 'react';
import { useCookies } from "react-cookie";
import OrdersMenu from '../../components/orders_menu';
import OrdersAccordion from '../../components/orders_accordion';

function addCookies(Component) {
    return function WrappedComponent(props) {
        const [cookies] = useCookies();
      return <Component {...props} cookies={cookies} />;
    }
  }

class Orderhistory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: null,
            loading: true,
            error: null,
            menu_items: null,
            selectedMonth: null,
            selectedYear: null
        };
    }

    //getYearFromChild(year) {
    getYearFromChild = (year) => {
        this.setState({selectedYear: year});
    }

    getMonthFromChild= (month) => {
        this.setState({selectedMonth: month});
    }
      
    async componentDidMount() {
        const cookies = this.props.cookies;

        var headers = new Headers();
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');
        headers.append('Authorization', 'Bearer ' + cookies.token);

        fetch("https://localhost/v1/orders/get_all_orders", {
            method: 'GET',
            headers: headers
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {             
                this.setState({ loading: false });

                if (data.error) {
                    this.setState({ error: data.msg });
                }
                else {
                    this.setState({ data, menu_items: data });
                }
            })
            .catch(error => {
                console.error('There was a problem with the fetch operation:', error);
                this.setState({ loading: false, error: error.message });
            });
    }

    
    render() {
        const { data, loading, error, menu_items, selectedMonth, selectedYear } = this.state;

        return (
            <div>
                {loading && <h5 className="text-center">Haetaan tietoja...</h5>}
                {error && <p>Error: {error}</p>}
                {data && 
                    
                    <div>
                        <div className="overlay" style={{ backgroundImage: `url('images/02.jpg')`, opacity: 0.25, zIndex: 0, backgroundRepeat: 'no-repeat' }}></div>
                        <div className='d-none d-sm-none d-md-block mt-50'></div>
                        <div className="content">
                            <div className="container d-print-none mb-5">
                                <div className="row">
                                    <div className="col-md-3 col-xs-12 mb-4">
                                        < OrdersMenu
                                            currentTab='History'
                                            years={menu_items}
                                            sendDataToParent={this.getYearFromChild} 
                                            sendMonthToParent={this.getMonthFromChild} 
                                        />
                                    </div>
                                    <div className="col-md-9 col-xs-12">
                                    <OrdersAccordion
                                        years={menu_items}
                                        selectedYear = {selectedYear}
                                        var month = {selectedMonth}
                                    />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>       
                }
            </div>
        );
    }
}

const OrderhistoryPage = {
    routeProps: {
        path: '/customers/orderhistory',
        Component: addCookies(Orderhistory)
    },
    name: 'Orderhistory',
}
export default OrderhistoryPage;
