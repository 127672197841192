import React, { useEffect } from 'react';

const Logout_page = () => {

  useEffect(() => {
    document.cookie = `${'token'}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
    document.cookie = `${'user'}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
 }, []);

  return (
    <div className="d-flex justify-content-center w-100">
      <div className="p-2 square border border-2 rounded w-50">
        <h5>Sinut on nyt kirjattu ulos palvelusta</h5>
        <p className="small">Kiitos käytöstä ja tervetuloa uudelleen.</p>
      </div>
    </div>
  );
}

const LogoutPage = {
  routeProps: {
      path: '/customers/logout',
      Component: Logout_page
  },
  name: 'Logout',
}

export default LogoutPage;